import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledComponentsRegistry"] */ "/vercel/path0/app/StyleComponentsRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/framework/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/framework/styles/reset.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/normalize.css@8.0.1/node_modules/normalize.css/normalize.css");
