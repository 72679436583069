'use client';

import { type ReactNode } from 'react';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';

import { MockProvider } from '_src/contexts/mock/MockProvider';
import { ThemeProvider } from '_src/app/theme';

export const Providers = ({
  session,
  children,
}: {
  session: Session | null;
  children: ReactNode;
}) => (
  <MockProvider>
    <SessionProvider session={session}>
      <ThemeProvider>{children}</ThemeProvider>
    </SessionProvider>
  </MockProvider>
);
